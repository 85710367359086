.user-list-actions {
    display: flex;
    justify-content: flex-end;
}

.user-list-row {
    display: flex;
    cursor: pointer;
}

.user-list-row:not(.header):hover {
    background-color: #0c8081;
    color: white;
}

.user-list-row.header {
    font-weight: bold;
}

.user-list-name {
    flex: 0 0 50%;
}

.user-list-email {
    flex: 0 0 50%;
}

.user-list-detail {
    display: none;
}

.user-list-detail.open {
    display: flex;
    flex-direction: column;

    border: 1px solid #0c8081;
    padding: 20px;
}

.user-events {

}

.user-events-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 5px;
}

.user-events-list .badges {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.user-events-list .badges .badge {
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 1px;
    margin-bottom: 1px;
    border-radius: 20px;
    background-color: #0c8081;
    color: white;
}

.user-events-list .name {
    flex:  0 0 25%;
}

.user-events-list .actions {
    flex:  0 0 auto;
}