.footer {
    font-size: 18px;
    background: black;
    color: white;
}

.footer-content {
    width: 800px;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
    padding: 20px 0;
}