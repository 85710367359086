.competition-detail {
    background: black;
}

.competition-header {
    display: flex;
    padding: 20px 40px;
}

.competition-header .competition-title {
    flex: 1 1 auto;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.competition-header .competition-title .event {
    font-weight: 500;
}

.competition-header .competition-title .competition {
    font-weight: 200;
}

.competition-header .competition-cart {
    flex: 0 0 auto;
    position: relative;
}

.competition-header .competition-cart .cart-icon {
    height: 40px;
    cursor: pointer;
}

.cart {
    position: absolute;
    top: 100%;
    right: 0;
    width: 350px;
    background: white;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    z-index: 100;
    border-radius: var(--amplify-components-button-border-radius)
}

.cart-footer {
    display: flex;
    padding: 0 8px 8px 8px;
    justify-content: space-between;
}

.cart-footer .checkout {
    flex: 1 1 auto;
}

.cart-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: -1;
}

.cart-images {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;
    margin: 8px;
    overflow: auto;
    max-height: 290px;
}

.cart-images .line-item {
    display: flex;
    gap: 8px;
    align-items: center;
}

.cart-images .line-item .price{
    flex: 0 0 auto;
    font-size: 20px;
    font-weight: 500;
}

.cart-images .line-item .text{
    flex: 1 1 auto;
    font-size: 20px;
    font-weight: 500;
}

.cart-images .line-item .cart-image-container {
    flex: 0 0 auto;
    display: flex;
    position: relative;
    cursor: pointer;
}

.cart-images .line-item .cart-image-container .stack2 {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-10deg) scale(0.9);
    outline: 1px solid white;
}

.cart-images .line-item .cart-image-container .stack3 {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(10deg) scale(0.8);
    outline: 1px solid white;
}

.cart-images .line-item .cart-image-container:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/VisualEditor_-_Icon_-_Remove.svg/2048px-VisualEditor_-_Icon_-_Remove.svg.png);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 1;
}

.cart-images img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.competition-cart .cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #FF0000;
    color: #FFFFFF;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
    transition: padding-top 0s ease-in-out;
    margin-bottom: 5rem;
}

.image-container .thumb-container {
    flex: 1 1 33.3333%;
    max-width: 33.3333%;
    height: 0;
    padding-bottom: 33.3333%;
    overflow: hidden;
    position: relative;
    border: 1px solid black;
}

.searchresult .image-container {
    padding-top: 100px;
}

@media screen and (max-width: 800px) {
    .searchresult .image-container {
        padding-top: 79px;
    }
}

.no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90vw;
    text-align: center;
    color: white;
}

.filter-tags {
    display: flex;
    flex-wrap: no-wrap;
    gap: 8px;
    position: absolute;
    top: 90px;
}

.filter-tags .filter-tag {
    display: flex;
    flex-wrap: no-wrap;
    gap: 8px;
    box-shadow: 0 2.5px 5px rgb(0 0 0 / 25%);
    border-radius: 2em;
    padding: 2px 10px;
}

@media screen and (min-width: 700px) {
    .image-container .thumb-container {
        flex: 1 1 25%;
        max-width: 25%;
        padding-bottom: 25%;
    }
}

@media screen and (min-width: 900px) {
    .image-container .thumb-container {
        flex: 1 1 20%;
        max-width: 20%;
        padding-bottom: 20%;
    }
}
 
@media screen and (min-width: 1100px) {
    .image-container .thumb-container {
        flex: 1 1 16.666667%;
        max-width: 16.666667%;
        padding-bottom: 16.666667%;
    }
}

@media screen and (min-width: 1300px) {
    .image-container .thumb-container {
        flex: 1 1 14.2857%;
        max-width: 14.2857%;
        padding-bottom: 14.2857%;
    }
}

@media screen and (min-width: 1500px) {
    .image-container .thumb-container {
        flex: 1 1 12.5%;
        max-width: 12.5%;
        padding-bottom: 12.5%;
    }
}

.thumb-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
}

.thumb-container.in-cart {
    position: relative;
    outline-offset: -4px;
    outline: 3px solid deepskyblue;
}

.thumb-container.in-cart::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 60px 0 0;
    border-color: deepskyblue transparent transparent transparent;
}

.thumb-container.in-cart::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-image: url('../checkmark-256.png');
    background-size: 25px;
    background-position: 6px 7px;
    background-repeat: no-repeat;
}

.thumb-container .add-to-cart {
    cursor: pointer;
    position: absolute;
    right: 5px;
    width: 40px;
    height: 40px;
    bottom: 5px;

    display: none;
}

.thumb-container:hover .add-to-cart {
    display: block;
}

.hightlight-frame {
    position: fixed;
}
.hightlight-container {
    position: relative;
    display: flex;
}

.hightlight-container .image-loader {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
}

.hightlight-container .add-to-cart {
    position: absolute;
    height: 50px;
    bottom: -60px;
    right: 0;
    display: flex;
    background: white;
}

.hightlight-container .add-to-cart .add-to-cart-icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
}

.hightlight {
    object-fit: contain;
    max-width: 90vw;
    max-height: 80vh;
}

.hightlight-prev {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translate(0, -50%) rotate(180deg);
    cursor: pointer;

    width: 2em;

    box-sizing: content-box;
    padding: 6rem 1rem 6rem 5rem;
}

.hightlight-next {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    cursor: pointer;

    width: 2em;

    box-sizing: content-box;
    padding: 4rem 1rem 4rem 4rem;
}

@keyframes slideInFromLeft {
    0% {
        transform: translate(-54px, -54px);
        padding: 50px;
    }
    100% {
        padding: 10px;
        transform: translate(-14px, -14px);
    }
}

.face {
    position: absolute;
    cursor: pointer;
    --b: 4px;
    --c: rgba(255,255,255,0.66);
    --w: 20px;
    border: var(--b) solid #0000;
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(from 90deg at top var(--b) left var(--b),var(--_g)) 0 0 / var(--_p), conic-gradient(from 180deg at top var(--b) right var(--b),var(--_g)) 100% 0 / var(--_p), conic-gradient(from 0deg at bottom var(--b) left var(--b),var(--_g)) 0 100% / var(--_p), conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
    padding: 10px;
    transform: translate(-14px, -14px);
    transition: all 0.2s ease-in-out;
    box-sizing: content-box;

    animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.face:hover {
    transform: translate(-24px, -24px);
    padding: 20px;
}

.face.highlight {
    border: 1px solid transparent;
    --c: rgb(0 122 255);
}

.face .face-confidence {
    display: none;

    color: lime;
    position: relative;
    top: -25px;
}

.face.highlight .face-confidence {
    color: hotpink;
}

.background {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.90);
    backdrop-filter: blur(3px);
    cursor: pointer;
}

.page-container {
    position: relative;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);

    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);

    overflow: auto;
}

.page-container.loading,
.page-container.loading .background,
.page-container.loading .face {
    cursor: progress;
}

.inline-loader {
    width: auto;
    height: 1em;
    margin-left: 0.5em;
}

.bundle-option {
    display: flex;
    border: 1px solid #ccc;
    margin: 0 40px 40px 40px;
    padding: 20px;
    gap: 20px;
    align-items: center;
}

.bundle-image-stack {
    position: relative;
    flex: 0 0 100px;
    top: -15px;
}

.bundle-image-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    object-fit: contain;
    outline: 1px solid white
}

.bundle-image-item-1 {
    top: -5px;
    transform: rotate(10deg);
}

.bundle-image-item-2 {
    left: 15px;
    top: -15px;
    transform: rotate(0deg);
}

.bundle-image-item-3 {
    left: 30px;
    top: -10px;
    transform: rotate(-10deg);
}


.bundle-text {
    flex: 1 1 auto;
    font-size: 1.5em;
    font-weight: bold;
}

.bundle-add-to-cart {
    flex: 0 0 auto;
}

.slide {
    position: relative;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: all 0.3s linear;
}

.slide-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s linear;
    overflow: hidden;
}

.swiper-image {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: cover;
    transition: all 0.3s linear;
}

.image-detail.show {
    opacity: 1;
}

.competition-detail .swiper {
    background-color: rgba(0, 0, 0, 0.90);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.image-detail.hide {
    opacity: 0;
    pointer-events: none;
}

.scroll-content {
    position: absolute;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;

    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}

.detail-toggle {
    position: absolute;
    bottom: -20px;
    right: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgb(241,3,146);
    background: linear-gradient(135deg, rgba(241,3,146,1) 0%, rgba(255,151,0,1) 100%);
}

.detail-toggle::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    width: 50px;
    height: 50px;
    background: url(https://cdn-icons-png.flaticon.com/512/60/60564.png);
    background-size: 25px auto;
    background-repeat: no-repeat;
    background-position: center;
}


.show-detail .slide-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
}

.show-detail .slide-wrapper .swiper-image {
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 50vh;
}

.image-details {
    position: absolute;
    top: 50vh;
    left: 0;
    width: 100vw;
    height: 50vh;
    background: black;
    color: white;
}


.show-detail .detail-toggle {
    transform: rotate(180deg);
}

.image-details-content {
    width: 800px;
    max-width: 100vw;
    margin: auto;
    padding: 50px 15px;
}

.image-details-content .line {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.image-details-content .line .icon {
    width: 50px;
    height: auto;
}

.image-report {
    position: absolute;
    bottom: 20px;
    width: 40px;
    height: 40px;
    right: 20px;
    opacity: 0.75;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.image-report:hover {
    opacity: 1;
    transform: scale(1.1);
    transform-origin: center;
}

.report-modal {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.report-modal-headline {
    flex: 0 0 100%;
    padding: 0 20px;
}

.report-modal-preview {
    flex: 0 0 50%;
    padding: 0 20px;
}

.report-modal-preview img {
    width: 100%;
    height: auto;
}

.report-modal-form {
    flex: 0 0 50%;
    padding: 0 20px;
}

.report-modal-form select,
.report-modal-form textarea,
.report-modal-form input {
    width: 100%;
    margin-bottom: 20px;
}

.report-modal-form label {
    display: block;
}

.search-bar {
    position: fixed;
    z-index: 1;
    background: rgba(255,255,255,0.85);
    backdrop-filter: blur(5px);
    padding: 3px 15px;
    border-radius: 3rem;
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    width: 500px;
    max-width: 80vw;
    left: 50%;
    transform: translate(-50%, 0);
    top: 20px;
    transition: transform 0.3s ease-in-out;
}

.mobile-search-bar {
    display: none;
    z-index: 3;
}

.mobile-search-toggle {
    background: rgba(255,255,255,0.85);
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 3em;
    padding: 13px;
    position: fixed;
    right: 1em;
    top: 1em;
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    cursor: pointer;
    display: none;
    z-index: 3;
}

.mobile-search-close {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    color: white;
    z-index: 4;
    font-size: 1.15em;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.05rem;
    padding: 29px 5vw 29px 10px;
    display: none;
}

.mobile-search-input {
    position: fixed;
    top: 125px;
    left: 5vw;
    width: 90vw;
    height: 50px;
    background: rgba(255,255,255,0.85);
    backdrop-filter: blur(5px);
    border-radius: 3rem;
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    z-index: 2;
    font-family: 'Montserrat', sans-serif;
}

.mobile-search-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
    z-index: 2;
    filter: blur(5px);
}

.mobile-search-open .background-gradiant {
    height: 300px;
    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}

.mobile-search-input input {
    height: 50px;
    border-radius: 3em;
}

@media only screen and (max-width: 800px) {
    .search-bar {
        display: none;
    }

    .mobile-search-toggle {
        display: flex;
    }

    .mobile-search-open .mobile-search-toggle {
        display: none;
    }

    .mobile-search-open .mobile-search-bar {
        display: flex;
    }

    .mobile-search-open .mobile-search-close {
        display: flex;
    }

}


.competition-detail .new-reset-filter-button {
    flex: 0 0 auto;
    display: none;
    align-self: center;
    letter-spacing: 0.05rem;
    font-size: 0.9em;
    align-items: center;
    gap: 0.5em;
    background: #2298d436;
    padding: 2.5px 10px 2.5px 15px;
    border-radius: 1em;
    color: #5b6b8e;
    cursor: pointer;
}

.searchresult .new-reset-filter-button {
    display: flex !important;
}

@media screen and (max-width: 800px) {
    .searchresult .new-reset-filter-button {
        display: none !important;
    }
}

.competition-detail .new-reset-filter-button img {
    height: 1em;
    filter: invert(43%) sepia(10%) saturate(1422%) hue-rotate(183deg) brightness(94%) contrast(90%);
}

.search-bar form {
    width: 100%;
}

.search-bar input {
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.05rem;
}

.search-bar input::-webkit-search-cancel-button,
.mobile-search-bar input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
}

.search-bar input,
.search-bar input:active,
.search-bar input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.search-bar .amplify-field-group__inner-end .amplify-button {
    display: none;

    border-radius: 3rem;
    padding: 12px;
}

.competition-detail .swiper {
    z-index: 5 !important;
}

@media only screen and (max-width: 800px) {
    .competition-header {
        padding: 20px 20px;
    }

    .competition-header .competition-title .event,
    .competition-header .competition-title .competition {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal; 
        word-break: break-all;
    }

    .search-bar .amplify-searchfield {
        flex: 1 1 100% !important;
    }

    .search-bar .amplify-button {
        flex: 1 1 auto !important;
    }
 
    .bundle-option {
        margin: 0 20px 20px 20px;
        flex-wrap: wrap;
    }

    .bundle-image-stack {
        order: 1;
    }

    .bundle-text {
        order: 3;
    }

    .bundle-add-to-cart {
        order: 2;
        flex: 1 1 auto;
    }

    .slide-wrapper .swiper-image{
        max-width: 100vw;
        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);

    }

    .slide-wrapper .face {
        --b: 2.5px;
        --w: 12.5px;
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translate(-34px, -34px);
            padding: 30px;
            opacity: 0;
        }
        100% {
            padding: 10px;
            transform: translate(-14px, -14px);
            opacity: 1;
        }
    }

}

.close-detail {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    filter: brightness(0) invert(1);
    z-index: 99999999;
    opacity: 0.66;
    transition: all 0.2s ease-in-out;
}

.close-detail:hover {
    opacity: 1;
    transform: scale(1.1);
}

.arrow-right {
    position: absolute;
    right: 1vmin;
    top: 50%;
    transform: scale(1) translate(0, -50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3vmin 0 3vmin 3vmin;
    border-color: transparent transparent transparent rgba(255,255,255,0.66);

    transform-origin: top;
    transition: all 0.2s ease-in-out;
    z-index: 100000;
    cursor: pointer;
}

.arrow-right:hover {
    border-color: transparent transparent transparent rgba(255,255,255,1);
    transform: scale(1.1) translate(0, -50%);
}

.arrow-left {
    position: absolute;
    left: 1vmin;
    top: 50%;
    transform: scale(1) translate(0, -50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3vmin 3vmin 3vmin 0;
    border-color: transparent rgba(255,255,255,0.66) transparent transparent;

    transform-origin: top;
    transition: all 0.2s ease-in-out;
    z-index: 100000;
    cursor: pointer;
}

.arrow-left:hover {
    border-color: transparent rgba(255,255,255,1) transparent transparent;
    transform: scale(1.1) translate(0, -50%);
}

.slide .slide-wrapper .photographer-name {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-shadow: 0px 0px 15px rgba(0,0,0,1);
    font-family: 'Montserrat';
    font-weight: 600;
    color: white;

    font-size: 0.8em;
    bottom: 15px;
    left: 15px;    
}

@media screen and (min-width: 700px) {
    .slide .slide-wrapper .photographer-name {
        font-size: 1em;
        bottom: 20px;
        left: 20px;    
    }
}

.slide .slide-wrapper .photographer-name::before {
    content: "Foto von";
    font-size: 0.8em;
    font-weight: 500;
}

.background-gradiant {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
    z-index: 1;
    pointer-events: none;
}

.back-link,
.mobile-reset-filter {
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-left: 1em;
    font-size: 1.15em;
    margin-top: 29px;
    text-shadow: 0px 0px 5px black;
    cursor: pointer;
    letter-spacing: 0.05rem;
}

.mobile-search-open .back-link {
    display: none;
}

.back-link::before,
.mobile-reset-filter::before {
    content: "";
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png);
    width: 1em;
    height: 1em;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg);
}

.back-link::after {
    content: "Übersicht";
}

.mobile-reset-filter::after {
    content: "Filter zurücksetzen";
}

.mobile-reset-filter {
    display: none;
}

@media screen and (max-width: 800px) {
    .searchresult .back-link {
        display: none;
    }

    .back-link::after {
        content: "Übersicht";
    }

    .searchresult .mobile-reset-filter {
        display: flex;
        z-index: 4;
    }
}

.private-competition-login {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    max-width: 90vw;
    transform: translate(-50%, -50%);
    color: white;

    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.2rem;
}

.private-competition-login-error {
    color: red;
}

.private-competition-login input {
    color: white;
}

.private-competition-login Button {
    color: white;
}

.global-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20vmin;
    height: 20vmin;
}

.selfie-button {
    width: 2.5em;
    height: 2.5em;
    padding: 3px;
    cursor: pointer;
}

.mobile-selfie-button {
    width: 6.5em;
    height: 6.5em;
    padding: 1em;
    cursor: pointer;
    position: fixed;
    background: rgba(255,255,255,0.85);
    top: 260px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 5em;
    backdrop-filter: blur(5px);
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
}