.photographer-upload-page {
    width: 1000px;
    margin: auto;
    max-width: 90vw;
    margin-bottom: 40px;
}

.photographer-upload-page.step-2 {
    max-height: 100vh;
    overflow: auto;
}

.potographer-upload-scroll-content {
    flex: 0 0 auto;
    overflow: auto;
    height: calc(100vh - 90px);
}

.potographer-upload-footer {
    border-top: 1px solid #ccc;
    padding-top: 1em;
}

.photographer-upload-page .description-container {
    display: flex;
    gap: 8px;
    margin-bottom: 40px;
}

.photographer-upload-page .description-container .description {
    flex: 1 1 auto;
}

.photographer-upload-page .description-container .actions {
    flex: 0 0 auto;
    margin-left: 20px;
}

.photographer-upload-page .description-actions {
    flex: 0 0 auto;
    margin-top: 1rem;
}

.photographer-upload-page .upload-area {
    margin-bottom: 40px;
}

.photographer-upload-page .upload-image-list {

}

.photographer-upload-page .user-agreements {
    border: 1px solid rgb(195, 98, 255);
    border-radius: 5px;
    padding: 10px 15px;
    height: 122px;
    overflow: auto;
    margin-bottom: 30px;
    background: #f7f7f7;
}

.photographer-upload-page .upload-image-list .image-item {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 0 8px 4px 8px;
}

.photographer-upload-page .upload-image-list .image-item .file-name {
    flex: 1 1 auto;
}
.photographer-upload-page .upload-image-list .image-item .file-size {
    flex: 0 0 30%;
}
.photographer-upload-page .upload-image-list .image-item .actions {
    flex: 0 0 auto;
}

.photographer-upload-page .upload-image-list .image-item .actions img {   
    margin-right: 1em;
    height: 1.4em;

    cursor: pointer;
    transition: all 0.1s ease-in-out;
    transform: scale(1);
}

.photographer-upload-page .upload-image-list .image-item .actions img:hover {   
    transform: scale(1.1);
}


.photographer-upload-page .photographer-steps {
    display: flex;
    margin-bottom: 60px;
    margin-top: 40px;
    width: 100%;
    align-items: center;
}

.photographer-upload-page .photographer-steps .step {
    flex: 0 0 auto;
    font-size: 1.5rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    background: white;
    color: white;
    border-radius: 2rem;
    border: 3px solid #d7d7d7;
    position: relative;
}

.photographer-upload-page .photographer-steps .step::after {
    content: attr(data-step);
    position: absolute;
    top: 40px;
    width: 200px;
    left: -80px;
    color: black;
    font-size: 1rem;
}

.photographer-upload-page .photographer-steps .step.active {
    border: 3px solid black;
}

.photographer-upload-page .photographer-steps .step.done {
    background: black;
}

.photographer-upload-page .photographer-steps .step-divider {
    flex: 1 1 auto;
    height: 3px;
    background: #d7d7d7;
    border-radius: 5px;
}

.photographer-upload-page .photographer-steps .step-divider.active {
    background: #000;
}

.photographer-upload-page .photographer-steps .step-action {
    flex: 0 0 auto;
}

.photographer-upload-page .info-icon {
    padding: 2px 10px;
    border: 1px solid #858585;
    border-radius: 10em;
    margin-right: 0.5rem;
    color: #858585;
}

.photographer-upload-page .info-text {
    color: #858585;
}

.photographer-upload-page .pre-upload-info {
    margin-bottom: 1em;
}