.b-sidebar-event {
    display: flex;
    gap: 8px 12px;
    padding: 8px;
    border-radius: 4px;
}

.b-sidebar-event:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
}

.b-sidebar-event.selected {
    background: rgba(255, 255, 255, 0.15);
}

.b-sidebar-event.selected:hover {
    background: rgba(255, 255, 255, 0.2);
}

.b-sidebar-event-img {
    flex: 0 0 48px;
    height: 48px;
    max-width: 48px;
    background: black;
    border-radius: 4px;
    object-fit: cover;
}

.b-sidebar-event-infowrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 238px;
}

.b-sidebar-event-name {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.b-sidebar-competitions {

}

.b-sidebar-competition {
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 8px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.b-sidebar-competition:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.03);
}

.b-sidebar-competition.selected {
    background: rgba(255, 255, 255, 0.06);
}

.b-sidebar-competition.selected:hover {
    background: rgba(255, 255, 255, 0.09);
}

.b-sidebar-competition-name {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.b-sidebar-competition-info {
    color: #BDBDBD;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.b-sidebar-event-tags {
    display: flex;
    gap: 4px;
    position: relative;
    overflow: hidden;
}

.b-sidebar-event-tags::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(90deg, #12121200 0%, #121212ff 100%);   
    pointer-events: none;
}

.b-sidebar-event:hover .b-sidebar-event-tags::after {
    background: linear-gradient(90deg, #2a2a2a00 0%, #2a2a2aff 100%);   
}

.b-sidebar-event.selected .b-sidebar-event-tags::after {
    background: linear-gradient(90deg, #32323200 0%, #323232ff 100%);   
}

.b-sidebar-event.selected:hover .b-sidebar-event-tags::after {
    background: linear-gradient(90deg, #41414100 0%, #414141ff 100%);   
}

.b-sidebar-event-tags .b-sidebar-event-tag {
    font-size: 12px;
    padding: 1px 10px;
    border-radius: 12px;
    text-wrap: nowrap;
}

.b-sidebar-event-tags .b-sidebar-event-tag.published {
    background: #4CAF50;
    color: white;
}

.b-sidebar-event-tags .b-sidebar-event-tag.yellow {
    background: #FFC107;
    color: black;
}


.b-sidebar-event-tags .b-sidebar-event-tag.draft {
    background: #FFC107;
    color: black;
}