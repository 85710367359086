.backoffice .images {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.backoffice .images .image {
    flex: 0 0 100px;
    max-width: 100px;
    cursor: pointer;
}

.backoffice .images .image.selected {
    outline: 3px solid #cb45cb;
    outline-offset: -2px;
}

.images-actions {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
}

.images-actions .images-actions-count {
    flex: 1 1 auto;
}