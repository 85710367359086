
.event-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.event-page-content {
    width: 90vw;
    margin: auto;
    max-width: 800px;
}
.event-page-content .event-titles {
    margin-top: 0;
    margin-bottom: 2em;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    hyphens: auto;
}

.event-page-content .event-titles h1 {
    font-weight: 500;
}

.event-page-content .event-titles h3 {
    font-weight: 300;
}

.event-page-content .competitions {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    margin-bottom: 5rem;
}

.event-page-content .competition-image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.event-page-content .competition-tile {
    flex: 1 1 auto;
    max-width: 33.333%;
    min-width: 33.333%;
    padding: 10px;
}

.event-page-content .competition-hover-effect {
    box-shadow: 0 0 0 rgb(0 0 0 / 0%);
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
}

.event-page-content .competition-hover-effect:hover {
    box-shadow: 0 10px 20px rgb(0 0 0 / 40%);
}

.event-page-content .competition-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.25em;
    background: black;
    color: white;
    padding: 2.5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-page-content .is-private img {
    width: 2em;
    height: 2em;
    object-fit: contain;
    padding: 5px;
}

.competition-tile .competition-image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-page-content .is-private {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    pointer-events: none;
}

.competition-tile .competition-image-wrapper .is-private img {
    top: 55%;
    left: 50%;
    width: 30%;
    height: 30%;
    object-fit: contain;
    transform: translate(-50%, -100%);
}

.competition-tile .competition-image-wrapper .is-private .private-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, 0%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: min(5vmin, 1.5em);
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    
    width: 400px;
    max-width: 80vw;
}

@media screen and (max-width: 1000px) {
    .event-page-content .competition-tile {
        max-width: 50%;
        min-width: 50%;
    }
}

@media screen and (max-width: 700px) {
    .event-page-content .competition-tile {
        max-width: 100%;
        min-width: 100%;
    }
    
    .event-page-content .competition-image-wrapper {
        padding-bottom: 60%;
    }

    .event-page-content .competition-name {
        font-size: 1em;
    }
}