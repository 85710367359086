.report-list-row {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    padding: 5px;
    transition: all 0.2s ease-in-out;
}

.report-list-row:not(.header):hover {
    background-color: #0e989a;
    color: white;
    cursor: pointer;
}

.report-list-row.active {
    background-color: #0c8081;
    color: white;
}

.report-list-row.header {
    font-weight: bold;
}

.report-list-image {
    flex: 0 0 50px;
    max-width: 50px;
    margin-right: 10px;
}

.report-list-email {
    flex: 1 1 auto;
}
.report-list-reason {
    flex: 0 0 30%;
}

.report-list-detail {
    display: flex;
    flex-wrap: nowrap;
    padding: 20px 0;
    gap: 20px;
}

.report-list-detail-image {
    flex: 0 0 auto;
}

.report-list-detail-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.report-list-detail-info {
    flex: 0 0 auto;
    width: 100%;
    border: 1px solid #0c8081;
    padding: 10px;
    margin-bottom: 10px;
}

.report-list-detail-user {
    flex: 0 0 auto;
    width: 100%;
    border: 1px solid #0c8081;
    padding: 10px;
    margin-bottom: 10px;
}

.report-list-detail-info b,
.report-list-detail-user b {
    padding-right: 10px;
}

.report-list-detail {
    display: none;
}

.report-list-row.active + .report-list-detail{
    display: flex;
}