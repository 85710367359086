.modal {
    position: fixed;
    top: 50vh;
    width: 800px;
    max-width: 90vw;
    left: 50vw;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 102;
}

.modal-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 101;
}