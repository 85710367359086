
.backoffice {
    background: black;
    color: white;
    display: grid;
    grid-template-columns: 330px auto;
    grid-template-rows: 0 100%;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    font-family: 'Montserrat', sans-serif;
}

.backoffice .backoffice-sidebar {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    background: black;
    color: white;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.backoffice .backoffice-header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: #FFFFFF;
    width: 100vw;
    height: 40px;
    z-index: 100;
}

.backoffice .backoffice-body {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;

    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background: black;
    overflow: auto;
}

.backoffice-elevation {
    background: #121212;
    margin: 8px;
    border-radius: 10px;
}

.backoffice-elevation.stretch {
    flex: 1 1 auto;
}

.backoffice .events {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.backoffice .events .event {
    flex: 0 0 25%;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .backoffice .events .event {
        flex: 0 0 40%;
    }
}

.backoffice .event-wrapper {
    height: 0;
    padding-bottom: 60%;
}

.backoffice .event-sizer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png");
    background-size: cover;
    background-position: center;

    color: black;
    border-radius: 8px;
    cursor: pointer;
}

.backoffice .event-sizer .event-title {
    font-weight: 600;
    font-size: 24px;
    padding: 10px;
    text-shadow: 0px 0px 10px white;
}

.backoffice .event.active .event-sizer {
    outline: 2px solid rgb(195, 98, 255);
}

.backoffice .create-event .event-sizer{
    background: #A0A0B0;
    background-image: url(https://cdn-icons-png.flaticon.com/512/992/992651.png);
    background-position: center;
    background-size: 30%;
    background-repeat: no-repeat;
    color: black;
}

.backoffice .onboarding {
    display: flex;
    align-items: baseline;
    border: 1px solid #E0E0E0;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.backoffice .onboarding pre {
    flex: 1 1 auto;
    overflow: auto;
}

.backoffice .onboarding button {
    flex: 0 0 auto;
}

.backoffice .inline-loader {
    width: auto;
    height: 1em;
    margin-left: 0.5em;
}

.backoffice-header .nav-tabs {
    display: flex;
}

.backoffice .swiper {
    padding-right: 100px;
    margin-left: -20px;
    padding-left: 20px;
    margin-right: -20px;
}

.backoffice .breadcrumb {
    margin-bottom: 40px;
    gap: 0;
}

.backoffice .breadcrumb .breadcrumb-item {
    color: white;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.backoffice .breadcrumb .breadcrumb-item:last-child {
    color: #A0A0B0;
}

.backoffice .breadcrumb .breadcrumb-item:not(:last-child):hover span {
    cursor: pointer;
    text-decoration: underline;
}

.backoffice .breadcrumb .breadcrumb-item:not(:last-child)::after {
    content: ">";
    margin: 0 8px;
    text-decoration: none !important;
}

.backoffice .main-content-elevation {
    padding: 10px 20px 20px;
}

.backoffice .amplify-heading {
    color: white;
}

.backoffice {
    --amplify-components-button-color: white;
    --amplify-components-fieldcontrol-color: white;
    --amplify-components-field-label-color: white;
    --amplify-components-text-color: white;
}

.backoffice .amplify-textfield {
    --amplify-components-fieldcontrol-color: white;   
}

.backoffice .amplify-passwordfield {
    --amplify-components-button-color: white;
}

.backoffice .modal-background {
    backdrop-filter: blur(5px);
}

.backoffice .modal {
    background: rgba(25,25,25,0.85);
    border: 0.5px solid rgb(70,70,70);
    border-radius: 8px;
}

.backoffice .backoffice-main-link {
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 16px;
    padding: 10px;
}


@keyframes hoverColor {
    0%   {color:rgb(255, 0, 238);}
    33%  {color:rgb(255, 183, 0);}
    66%  {color:rgb(10, 217, 172);}
    100% {color:rgb(255, 0, 238);}
}

.backoffice .backoffice-main-link:hover {
    cursor: pointer;
    color: hsl(172, 100%, 88%);

    animation-name: hoverColor;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
}

.backoffice .material-symbols-outlined.action:hover {
    cursor: pointer;
    color: hsl(172, 100%, 88%);

    animation-name: hoverColor;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
}