.photographer-list {
    margin-top: 30px;
    margin-bottom: 30px;
}


.photographer-body {
    display: none;
}
.photographer-container.active .photographer-body {
    display: block;
}

.photographer-container {
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}

.photographer-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 16px;
}

.photographer-head::after {
    content: 'expand_less';
    font-family: 'Material Symbols Outlined';
    font-size: 24px;
    display: block;

    transform: rotate(0deg);
    transform-origin: center;
    transition: transform 0.3s;
}

.photographer-head.new::after {
    content: 'person_add';
}



.photographer-container.active .photographer-head::after {
    transform: rotate(180deg);
}

.photographer-container.active .photographer-head.new::after {
    transform: rotate(360deg);
}

.photographer-head .name {
    flex:  1 1 auto;
}

.photographer-head .status {
    flex: 0 0 auto;
    padding: 2px 15px;
    border-radius: 20px;
    font-size: 14px;
}

.photographer-head .status.pending {
    color: #FF9800;
    background-color: #FFF3E0;
}

.photographer-head .status.submitted {
    color: #2196F3;
    background-color: #BBDEFB;
}

.photographer-head .status.merged {
    color: #4CAF50;
    background-color: #C8E6C9;
}

.direct-upload {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.direct-upload .image img {
    height: 100px;
}



.uploaded-by-fotograph {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
}

.uploaded-success-image {
    flex: 0 0 auto;
}

.uploaded-success-text {
    flex: 1 1 auto;
}

.uploaded-by-fotograph button {
    flex: 0 0 auto;
}

.uploaded-by-fotograph .uploaded-success-image img{
    height: 50px;

}