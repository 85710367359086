.homepage {
    font-size: 18px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homepage {
    font-family: 'Montserrat', sans-serif;
}

.homepage .hero {
    position: relative;
    width: 100dvw;
    height: 100dvh;
}

.homepage .hero .hero-text {
    position: absolute;
    bottom: 5vmin;
    left: 5vmin;
}

.homepage .hero h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 15vmin;
    color: white;
    text-shadow: 0 0 20px rgba(0,0,0,0.5);
    font-weight: 600;
    margin: 0;
}

.homepage .hero p {
    font-family: 'Montserrat', sans-serif;
    font-size: 4vmin;
    color: white;
    text-shadow: 0 0 10px black;
    font-weight: 400;
    margin: 0;
}

.homepage .hero video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.homepage .content {
    width: 800px;
    max-width: calc(100vw - 40px);

    margin: 0 auto;
    margin-bottom: 5rem;
}

.homepage .footer {
    background: black;
    color: white;
}

.homepage .footer-content {
    width: 800px;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
    padding: 20px 0;
}

.homepage .service-info {

}

.homepage .service-info > div h3 {
    margin: 0;
}

.homepage .service-info > div p {
    margin: 0.5rem 0 0 0;
}

.homepage .service-info > img {
    height: 128px;
    border-radius: 8px;
}

a,
a:visited {
    color: white;
    text-decoration: none;
}

a:hover {
    color: #0c8081;
    text-decoration: none;
}

.event-promo {
    display: flex;
}

.event-promo img {
    width: 100%;
    aspect-ratio: 3;
    object-fit: cover;
}

.event-name {
    background: #000;
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-size: 1.25em;
    font-weight: 400;
    overflow: hidden;
    padding: 2.5px 10px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
}