.backoffice .tabs {
    display: flex;
    margin-bottom: 20px;
}

.backoffice .tab {
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.backoffice .tab.active {
    border-bottom: 1px solid white;
}


.backoffice .competitions {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.backoffice .competitions .competition {
    flex: 0 0 25%;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .backoffice .competitions .competition {
        flex: 0 0 40%;
    }
}

.backoffice .competition-wrapper {
    height: 0;
    padding-bottom: 60%;
}

.backoffice .competition-sizer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png");
    background-size: cover;
    background-position: center;

    color: black;
    border-radius: 8px;
    cursor: pointer;
}

.backoffice .competition-sizer .competition-title {
    font-weight: 600;
    font-size: 24px;
    padding: 10px;
    text-shadow: 0px 0px 10px white;
}

.backoffice .competition.active .competition-sizer {
    outline: 2px solid rgb(195, 98, 255);
}

.backoffice .create-competition .competition-sizer{
    background: #A0A0B0;
    background-image: url(https://cdn-icons-png.flaticon.com/512/992/992651.png);
    background-position: center;
    background-size: 30%;
    background-repeat: no-repeat;
    color: black;
}