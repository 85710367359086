.direct-upload {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    cursor: pointer;
}

.direct-upload .image {
    text-align: center;
}

.direct-upload .image img {
    height: 100px;
}

.upload-container {
    position: relative;
}

#file-upload {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}