.selfie-modal {
    width: 540px;   
}

.selfie-container .selfie-camera {
    width: 500px;
    height: 500px;
    position: relative;
    margin-bottom: 56px;
}

@media screen and (max-width: 800px) {
    .selfie-container .selfie-camera {
        width: 330px;
        height: 330px;

        max-width: calc(90vw - 40px);
        max-height: calc(90vw - 40px);
    }
}

.selfie-container .selfie-camera video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selfie-container .selfie-camera img {
    width: 500px;
    height: 500px;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    opacity: 0.33;
}

@media screen and (max-width: 800px) {
    .selfie-container .selfie-camera img {
        width: 330px;
        height: 330px;

        max-width: calc(90vw - 40px);
        max-height: calc(90vw - 40px);
    }
}

.selfie-container .selfie-camera button {
    width: 100%;
    border: none;
    line-height: 3;
    background: black;
    color: white;
    font-family: 'Montserrat';
    cursor: pointer;
}